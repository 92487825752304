import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { Box } from '@primer/react';
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontContainer = makeShortcode("DoDontContainer");
const Do = makeShortcode("Do");
const Caption = makeShortcode("Caption");
const Dont = makeShortcode("Dont");
const KeyboardKey = makeShortcode("KeyboardKey");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h2>{`Usage`}</h2>
    <p>{`The underline nav provides navigation links to let users switch between 2 or more related views without leaving their current context.`}</p>
    <p>{`If you want to use this pattern without any linked items, use the `}<a parentName="p" {...{
        "href": "/components/underline-panels"
      }}>{`underline panels`}</a>{` component.`}</p>
    <h3>{`Best practices`}</h3>
    <ul>
      <li parentName="ul">{`Each tab panel should have discrete content with a unique URL, not just different formats to view the same content.`}</li>
      <li parentName="ul">{`One of the tabs should be selected by default when the user loads the page.`}</li>
      <li parentName="ul">{`Avoid overwhelming the user with too many tabs. Instead consider a navigation structure like a `}<a parentName="li" {...{
          "href": "/components/nav-list"
        }}>{`nav list`}</a>{` that's meant to handle more links.`}</li>
      <li parentName="ul">{`Each view should have enough information so the user can complete their tasks without switching back and forth between tabs.`}</li>
      <li parentName="ul">{`Views should be able to be navigated in any order. This is not a pattern for navigating stepped flows.`}</li>
    </ul>
    <h2>{`Anatomy`}</h2>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/175638/186432408-c2479dd2-31fe-4261-9f9a-e67d49b28e72.png",
        "alt": "Anatomy of the UnderlineNav component"
      }}></img></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Item`}</strong>{`: Tabs to switch between views when activated.`}</li>
      <li parentName="ul"><strong parentName="li">{`Current item`}</strong>{`: The item currently selected is highlighted with an underline.`}</li>
      <li parentName="ul"><strong parentName="li">{`Label text`}</strong>{`: Describes the navigation item.`}</li>
      <li parentName="ul"><strong parentName="li">{`Leading icon (optional)`}</strong>{`: An icon that identifies the navigation item.`}</li>
      <li parentName="ul"><strong parentName="li">{`Counter (optional)`}</strong>{`: Displays a count next to the label.`}</li>
      <li parentName="ul"><strong parentName="li">{`Overflow menu`}</strong>{`: A disclosure menu that displays additional items when there are too many to fit in the available space.`}</li>
    </ul>
    <h3>{`Label text`}</h3>
    <p>{`The title of the view associated with that tab. Keep labels clear, short, and concise.`}</p>
    <p>{`Do not rely on leading visuals, tooltips, or supplemental descriptions to communicate what the tab represents.`}</p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://github.com/primer/design/assets/2313998/b96d853e-3a03-47c7-854b-cd69914f6d80" role="presentation" width="456" />
    <Caption mdxType="Caption">Use label text that clearly communicates what the tab represents.</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://github.com/primer/design/assets/2313998/8d703e04-73f2-4d0c-898d-3a136d193af3" role="presentation" width="456" />
    <Caption mdxType="Caption">Don't rely on supplemental content to communicate what the tab represents.</Caption>
  </Dont>
    </DoDontContainer>
    <h3>{`Leading icons`}</h3>
    <p>{`Use an `}<a parentName="p" {...{
        "href": "/foundations/icons"
      }}>{`Octicon`}</a>{` to improve the scannability of common items, but only when relevant. When adding leading icons, all items in the navigation should have one.`}</p>
    <p>{`Make sure that icons used are consistent across views, and that they are not repeated for different navigations across the product.`}</p>
    <p>{`Label text is required. Leading icons are just a "bonus" affordance and should not be the only way to identify the navigation item.`}</p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://github.com/primer/design/assets/2313998/58b54936-37d4-41d6-a263-7ec49993deb0" role="presentation" width="456" />
    <Caption mdxType="Caption">Use leading icons as an enhancement.</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://github.com/primer/design/assets/2313998/ad989a92-f078-454b-9649-7add73785c92" role="presentation" width="456" />
    <Caption mdxType="Caption">Don't exclude label text.</Caption>
  </Dont>
    </DoDontContainer>
    <h3>{`Counters`}</h3>
    <p>{`You may include a counter to indicate the number of related resources contained in the desitination page, such as the number of open issues.`}</p>
    <p>{`When loading multiple counters asynchronously, wait for all the data to be ready before displaying the counters, so you can avoid multiple layout shifts. Use the counter loading state while waiting for the data.`}</p>
    <h2>{`Hierarchy`}</h2>
    <p>{`Tabs may be used to switch between content at different levels of hierarchy:`}</p>
    <ul>
      <li parentName="ul">{`subpages within a larger context`}</li>
      <li parentName="ul">{`content panels within a section of a page`}</li>
    </ul>
    <Box display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 3
    }} mdxType="Box">
    <div>
        <img src="https://github.com/primer/design/assets/2313998/bd6732fc-3e62-4e2e-afc2-d3312a22e8c2" alt="A page with three top-level tabs. Then, a placeholder heading and description followed by more tabs to separate subpage content. Top-level tabs are highlighted." width="456" />
        <Caption mdxType="Caption">Subpage tabs</Caption>
    </div>
    <div>
        <img src="https://github.com/primer/design/assets/2313998/314e0800-3dcc-4bb6-91de-7608db202a0c" alt="A page with three top-level tabs. Then, a placeholder heading and description followed by more tabs to separate subpage content. Inner subpage content tabs are highlighted." width="456" />
        <Caption mdxType="Caption">Page content tabs</Caption>
    </div>
    </Box>
    <p>{`Avoid more than 2 levels of tab hierarchy. If you need more than 2 levels of hierarchy, consider:`}</p>
    <ul>
      <li parentName="ul">{`rethinking your information architecture`}</li>
      <li parentName="ul">{`removing a layer of hierarchy and putting more content on the page`}</li>
      <li parentName="ul">{`using a `}<a parentName="li" {...{
          "href": "/components/nav-list"
        }}>{`nav list`}</a>{` for a level of hierarchy`}</li>
    </ul>
    <h3>{`Stacking`}</h3>
    <p>{`Don't stack multiple underline navs or underline panels directly on top of eachother to convey hierarchical levels of navigation. Instead, consider using a `}<a parentName="p" {...{
        "href": "/components/nav-list"
      }}>{`nav list`}</a>{` for a higher level of hierarchy.`}</p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://github.com/primer/design/assets/2313998/3b9c4160-3f9d-4a28-854f-9d50dcce4aaa" role="presentation" width="456" />
    <Caption mdxType="Caption">Limit the levels of tab hierarchy</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://github.com/primer/design/assets/2313998/72a9b78d-3227-48ab-a62c-bb44ed4d3be2" role="presentation" width="456" />
    <Caption mdxType="Caption">Don't stack tabs or create deep levels of tab hierarchy</Caption>
  </Dont>
    </DoDontContainer>
    <h2>{`Layout`}</h2>
    <h3>{`Placement`}</h3>
    <p>{`Underline nav should be placed directly above the content it affects.`}</p>
    <h3>{`Alignment`}</h3>
    <p>{`Align the the underline nav to the left and, when possible, make the component span the available width.`}</p>
    <p>{`The left and right edges of the underline nav should not extend beyond the width of the content it affects.`}</p>
    <h3>{`Clear beginning and end`}</h3>
    <p>{`It should be clear where the tab-switchable content begins and ends. Use spacing, borders, headings, or other visual cues to make it clear where the tab-switchable content ends.`}</p>
    <h3>{`Responsive design`}</h3>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/175638/229456836-bfa1ddbb-5cef-4dbc-a0db-da77814c63a2.png",
        "alt": "The underline nav without overflowing items and the component on a smaller viewport showing the overflow menu"
      }}></img></p>
    <p>{`Underline nav handles overflowing items automatically by collapsing them into a disclosure menu. When some of the items don't fit the available space, the component will first hide leading icons to economize space and then move items to the overflow menu one by one.`}</p>
    <h2>{`Accessibility`}</h2>
    <p>{`Set `}<inlineCode parentName="p">{`aria-current`}</inlineCode>{` to `}<inlineCode parentName="p">{`"page"`}</inlineCode>{` to indicate that the item represents the current page. Set `}<inlineCode parentName="p">{`aria-current`}</inlineCode>{` to `}<inlineCode parentName="p">{`"location"`}</inlineCode>{` to indicate that the item represents the current location on a page.`}</p>
    <h3>{`Keyboard navigation`}</h3>
    <p>{`Items can be activated using a cursor or keyboard`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Key(s)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><KeyboardKey mdxType="KeyboardKey">{`Enter`}</KeyboardKey></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Activates the focused item.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><KeyboardKey mdxType="KeyboardKey">{`Tab`}</KeyboardKey></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Moves focus to the next item.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="UnderlineNav" mdxType="AccessibilityLink" />
    <h2>{`Related links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/nav-list"
        }}>{`Nav list`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/segmented-control"
        }}>{`Segmented control`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/underline-panels"
        }}>{`Underline panels`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/ui-patterns/navigation"
        }}>{`Navigation`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      